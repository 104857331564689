<template>
    <Form>
    <Card>
        <p>{{ currencyName }}</p>
        <p>{{ $t('price_sell') }}: {{ floatToCash(price) }}</p>
    </Card>
    <p style="font-size: 1.5em; margin: 5px">{{ $t('avaible_to_sell') }} {{ avaibleToSell.toFixed() }}</p>
    <Input v-bind:value="amount" @input="amount = $event.target.value" :placeholder="$t('amount')" type="number"/>
    <Button @click="sellCurrency">{{ $t('sell') }}</Button>
    </Form>
</template>
<script>
import { mapActions, mapState } from "vuex"
import Card from "@/components/UI/Card"
import Input from "@/components/UI/Input"
import Button from "@/components/UI/Button"
import Form from "@/components/UI/Form"
import api from "@/api"
import { floatToCash, setTitle } from "@/funcs"

export default {
    components: {
        Card, Input, Button, Form,
    },
    data() {
        return {
            amount: NaN,
            price: 0,
            currency: this.$route.params.currency,
        }
    },
    computed: {
        ...mapState({
            currencies: state => state.currencies.currencies,
        }),
        currencyName() {
            return this.$t('currencies.' + this.currency)
        },
        avaibleToSell() {
            for (const el of this.currencies) {
                if (el.ticker === this.currency) {
                    return el.amount
                }
            }
            return 0.0
        },
    },
    methods: {
        ...mapActions({
            fetchSymbolsWithoutCache: "mySymbols/fetchSymbolsWithoutCache",
            fetchUserWithoutCache: "user/fetchUserWithoutCache",
            fetchCurrenciesWithoutCache: "currencies/fetchCurrenciesWithoutCache",
            fetchCurrencies: "currencies/fetchCurrencies",
        }),
        async sellCurrency() {
            if (this.amount === "") {
                alert(this.$t('alerts.only_numbers'))
                return
            }
            const amount = Number(this.amount)
            const currency = this.currency
            if (amount <= 0) {
                alert(this.$t('alerts.only_positive_numbers'))
                return
            }
            try {
                await api.post(`currency/sell-currency?currency=${currency}&amount=${amount}`)
            } catch (e) {
                alert(this.$t('alerts.not_enough_currencies_to_sell'))
                return
            }
            if (amount === 1) {
                alert(this.$t('alerts.currency_sold'))
            } else {
                alert(this.$t('alerts.currencies_sold'))
            }
            this.fetchSymbolsWithoutCache()
            this.fetchUserWithoutCache()
            this.fetchCurrenciesWithoutCache()
        },
        floatToCash,
    },
    mounted() {
        api.get(`currency/get-price?currency=${this.currency}`).then(resp => {
            this.price = resp.data
        })
        this.fetchCurrencies()
        setTitle(this.$t('sale_currency').replace("%name%", this.currencyName))
    }
}
</script>
